import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUs from "../components/contactUs"

const ContactPage = ({ location }) => (
  <Layout>
    <SEO title="Contact Us" />
    <ContactUs location={ location } />
  </Layout>
)

export default ContactPage
